<template>
  <div>
    <b-list-group flush>
      <b-list-group-item
        v-for="(item, index) in value"
        :key="'form-element-reference-' + index"
      >
        <b-row>
          <b-col>
            <b-form-textarea
              :id="'form-element-reference-text-' + index"
              v-model="item.reference"
              @change="changed"
            />
          </b-col>
        </b-row>
        <uris-component
          :id="'form-element-reference-uris-' + index"
          v-model="item.uris"
          @change="changed"
        />
        <b-button
          v-t="'components.references.delete'"
          @click="deleteReference(index)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-button v-t="'components.references.new'" @click="addReference" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "ReferencesComponent",
  components: {
    UrisComponent: () => import("@/components/UrisComponent")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    deleteReference(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    addReference(element) {
      this.content.push({ reference: "", uris: [] });
      this.$emit("change");
    }
  }
};
</script>
